"use client";
import { IoMdCall } from "react-icons/io";
import Image from "~/_components/atoms/Image";

export default function Error() {
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-dark">
      <div className="container flex flex-col items-center justify-center px-5 text-white tablet:flex-row">
        <div className="max-w-md">
          <div className="text-5xl font-bold font-dark">Error</div>
          <p className="text-2xl font-light leading-normal">
            Xin lỗi, có vẻ đã có sự cố xảy ra đối với hệ thống của chúng tôi
          </p>
          <p className="mb-8">
            Nhưng xin đừng lo lắng, chúng tôi sẽ khắc phục vấn đề trong thời gian ngắn nhất
          </p>
          <a href="/" rel="noopener noreferrer" className="btn-primary">
            Quay lại trang chủ
          </a>
          <a href="tel:0899812205" className="mt-2 btn-primary ripple">
            <IoMdCall style={{ marginRight: "10px" }} />{" "}
            <span>Gọi cho chúng tôi: 0899 812 205</span>
          </a>
        </div>
        <div className="max-w-lg ml-5">
          <Image 
            src="/404cat.svg"
            width={380}
            height={300}
            alt="404cat"
            priority
            unoptimized
          />
        </div>
      </div>
    </div>
  );
}
