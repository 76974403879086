import Image from "next/image";

export default function ImageWithFallback({ src, alt, ...rest }) {
  return (
    <Image
      {...rest}
      alt={alt}
      src={src || "/blankImage.webp"}
      placeholder='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAABdFBMVEVHcEzCwsKsrKypqam1tbWtra2+vr6VlZWvr6+goKBycnJ8fHynp6e3t7fAwMC8vLyjo6OIiIisrKyjo6OsrKynp6e6urpwcHBwcHCXl5d/f3+np6e3t7e1tbW+vr65ubm4uLiUlJRfX1+bm5uAgICqqqpfX1+oqKiAgICnp6d/f3+rq6t/f3+2trazs7O0tLS3t7fAwMBgYGCWlpZgYGCAgICXl5eBgYFgYGBfX1+np6diYmJfX19gYGCXl5etra1fX1+Xl5dfX19/f3+srKyvr6/GxsbAwMB/f3+Xl5d/f39/f39jY2Ovr6+SkpKnp6enp6ewsLDMzMxfX1+oqKi0tLS3t7enp6fGxsa/v7/AwMC/v7+/v7+/v7/b29vY2NjX19fb29tfX1+WlpaXl5evr69fX1+Xl5eAgIB/f3+vr6+Xl5eXl5eXl5eurq7Gxsa/v7+/v7////+nf5dfX1+np6d/f3+Xl5evr6+3t7e/v7/Hx8feKomEAAAAc3RSTlMAIC0MCBcPAQQCFAfPHC9FWBtHNSHrbikNyOn46lJcr4VDnyg/Yvm2paP2Pf7EehL2eXpkSlZ7bj7O2zffZ+CYxOzw2zn6lrW9qnWPH+JWf4zZTK+Gp5qtvtHdnPL5qIwoXFuSs8ms9JWWtPz49qjc6+cofSycKAAAAtVJREFUaN7tmOlT2kAYxklIyCYEwi33rYLIoYCgKAie9b61au/7bmcAC/3nmwP90nZmk9ntOM4+n5ghu7+818MuJhMRERERERER0cMSEAQrZgQ/NptMztaxYrjGcafbnViu4KQIyYmuouUx9Funq2knp3xqLKqMbmeJQ12G9yvBlUwayNmqdDTIxCzifDkzU/1+f3pTyVD9LhIeLaQa7Cuaqsmh7BxrkPnGqJ+LDJpsbU2pkOkPcob4yrzCWFzya18+f3aEJCS+NoJsK2Ww1pPL829fjhgm+9MXaPKWXlEhwaq2nX9nTLjbmKEsaGri3wpOy4xtAauV+KuZzUzNiWdzayqRcMqZ4XjBKQBMAZQD7ebrBMCZJKujeSMr4IbxTUqiDHUZ+0hh3HyZgwiluP5tnTUCSQVUyKdXEC5FXfy6oAxZlhbJZ5hImOh61JC/gHJbrUkC5mGmaLA/2Lnmu3ZgD/MPutld3ksBE9E9EWdedbhYzBB2X/QuZGk9ZxKgt0fAuLfX64VaRfgllM+nc+jZ854i0Q3f7/Hr67hZF8Q2qUIWctArLFfD4VVR3xwehBTIpAc+wdLGhqTT7t2ToV5owaGjlsBm0115ej974GJwTwpjMRO7uH9XUNrh8kB2JWfwUsSMi17v+SoH9Tq+iDE3dYvKPGYhppinYqVwwVAzqh7ZE2mIR6XwYBAzFIpDg6QgIqHzg1LcUCQexSRDJzBrGSnuo4w1Vy4riieQFskYPdXwrJv+j9bCM/+wSoR/GVgi8ULuLxhARSREd0Z5Kg/X1t64/px9e+zH5QyihHryP2W1bt+Zv80RmHkyHD62o4HQhwrkVBs2YI9GbBgg7FeZsVZQi8JJ+VL4bESxb3y/nEFUFC73cXe3pdmL5WwwGISj3G1UR0VkHexxuVhtXzYmQ0o+HuvEAJ/shflVzHNpK8ROI/hPMTaW3L6IiIiIiIiIHpZ+A7g3gnshKAtBAAAAAElFTkSuQmCC'
    />
  );
};